import { useEffect, useState } from 'react'
import './Portada.css'
import { Link as ScrollLink } from 'react-scroll'

function Portada() {

  const [mobile, setMobile] = useState(window.innerWidth <= 800)

  useEffect(() => {
    window.addEventListener('resize', () => setMobile(window.innerWidth <= 800))
    return () => window.removeEventListener('resize', () => setMobile(window.innerWidth <= 800))
  }, [])

  return (
    <div className="Portada" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/imgs/portada/portada-" + (mobile ? "mobile.png)" : "desktop.png)") }}>
      <div className='Portada-header'>
        <div className='Portada-header-content'>
          <img className="Portada-header-logo" src={process.env.PUBLIC_URL + "/imgs/logo.svg"} alt="Barrier insumos médicos" />
          <a href="https://www.instagram.com/barrierinsumos" target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + "/imgs/ig.svg"} alt="Instagram" /></a>
          <a href="https://www.facebook.com/saludinsumos" target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + "/imgs/fb.svg"} alt="Facebook" /></a>
        </div>
      </div>
      <div className="Portada-content">
        <img className="Portda-Barrier" src={process.env.PUBLIC_URL + "/imgs/portada/logo.svg"} alt="Barrier" />
        <p className="Portada-title"><b>INSUMOS MÉDICOS</b><br />para profesionales de la salud</p>
        <ScrollLink className="Portada-button" to="Contacto" smooth>CONTACTANOS</ScrollLink>
      </div>
    </div>
  )
}

export default Portada