import './Contacto.css';
import Formulario from './Formulario'

function Contacto() {

  return (
    <div className="Contacto">
      <div className="Contacto-content">
        <h3>Completá el formulario y<br />¡Te asesoramos de inmediato!</h3>
        <div className="Contacto-main">
          <div className="Contacto-form">
            <Formulario />
          </div>
          <div className="Contacto-data">
            <div className="Contacto-data-line">
              <img src={process.env.PUBLIC_URL + "/imgs/contacto/mail.svg"} alt="" />
              <p>barrierweb@gmail.com</p>
            </div>
            <div className="Contacto-data-line">
              <img src={process.env.PUBLIC_URL + "/imgs/contacto/tel.svg"} alt="" />
              <p>11-6798-6758</p>
            </div>
            <div className="Contacto-data-line">
              <img src={process.env.PUBLIC_URL + "/imgs/contacto/pin.svg"} alt="" />
              <p>Av. Alvarez Jonte 2935, CABA</p>
            </div>
            <div className="Contacto-data-line">
              <a href="https://www.instagram.com/barrierinsumos" target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + "/imgs/ig.svg"} alt="Instagram" /></a>
              <a href="https://www.facebook.com/saludinsumos" target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + "/imgs/fb.svg"} alt="Facebook" /></a>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Contacto;