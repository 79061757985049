import './Insumos.css'

function Insumos() {

  const onLinkClick = insumo => {
    window.fbq('trackCustom', insumo)
    window.gtag('event', 'Tarjeta ' + insumo, {'Etiqueta': insumo})
  }

  return (
    <div className="Insumos" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/imgs/insumos/background.png)' }}>
      <div className="Insumos-content">
        <h3>Nuestros Productos</h3>
        <div className="Insumos-cards">
          <div className="Insumos-cards-inner">
            <a onClick={() => onLinkClick('Insumos Descartables')} href={process.env.PUBLIC_URL + "/pdf/insumos-descartables.pdf"} target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + "/imgs/insumos/insumos-descartables.png"} alt="Insumos descartables" /></a>
            <a onClick={() => onLinkClick('Alimentación Y respiratorios')} href={process.env.PUBLIC_URL + "/pdf/alimentacion-y-respiratorios.pdf"} target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + "/imgs/insumos/alimentacion-y-respiratorios.png"} alt="Alimentación y respiratorios" /></a>
            <a onClick={() => onLinkClick('Cirugía General')} href={process.env.PUBLIC_URL + "/pdf/cirugia-general.pdf"} target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + "/imgs/insumos/cirugia-general.png"} alt="Cirugía general" /></a>
            <a onClick={() => onLinkClick('Cirugía Alta Complejidad')} href={process.env.PUBLIC_URL + "/pdf/cirugia-alta-complejidad.pdf"} target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + "/imgs/insumos/cirugia-alta-complejidad.png"} alt="Cirugía de alta complejidad" /></a>
            <a onClick={() => onLinkClick('Curación de heridas')} href={process.env.PUBLIC_URL + "/pdf/curacion-de-heridas.pdf"} target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + "/imgs/insumos/curacion-de-heridas.png"} alt="Curación de heridas" /></a>
            <a onClick={() => onLinkClick('Ostomia e Incontinencia')} href={process.env.PUBLIC_URL + "/pdf/ostomia-e-incontinencia.pdf"} target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + "/imgs/insumos/ostomia-e-incontinencia.png"} alt="Ostomia e incontinencia" /></a>
            <a onClick={() => onLinkClick('Urología')} href={process.env.PUBLIC_URL + "/pdf/urologia.pdf"} target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + "/imgs/insumos/urologia.png"} alt="Urología" /></a>
            <a onClick={() => onLinkClick('Neurología intervencionismo')} href={process.env.PUBLIC_URL + "/pdf/neurologia-intervencionismo.pdf"} target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + "/imgs/insumos/neurologia-intervencionismo.png"} alt="Neurología intervencionismo" /></a>
            <a onClick={() => onLinkClick('Tratamiento de Mav')} href={process.env.PUBLIC_URL + "/pdf/tratamiento-de-MAV.pdf"} target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + "/imgs/insumos/tratamiento-de-MAV.png"} alt="Tratamiento de MAV" /></a>
          </div>
        </div>
        <a className="Insumos-button" href="" target="_blank" rel="noreferrer">MÁS PRODUCTOS</a>
      </div>
    </div >
  )
}

export default Insumos