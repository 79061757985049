import { useRef, useState } from 'react';
import './Formulario.css';
import { useHistory } from "react-router-dom";

function Formulario() {

  const history = useHistory();

  const modalRef = useRef()

  const [showModal, setShowModal] = useState(false)

  const [name, setName] = useState('')
  const [tel, setTel] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const submitForm = e => {
    e.preventDefault()
    if (name !== '' && tel !== '' && email !== '' && message !== '') {

      const scrollY = window.scrollY
      setShowModal(true)
      modalRef.current.style.top = scrollY + 'px'
      document.body.style.position = 'fixed'
      document.body.style.top = '-' + scrollY + 'px'

      const barrierForm = {
        "nombre": name,
        "telefono": tel,
        "email": email,
        "mensaje": message
      }

      const jsonData = JSON.stringify(barrierForm)

      let xhr = new XMLHttpRequest()
      xhr.open('POST', 'https://newsletterservice.com.ar/landing/api/barrierweb')

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            alert("Formulario enviado con éxito. Lo contactaremos a la brevedad.")
            window.gtag('event', 'conversion', { 'send_to': 'AW-401647337/O6ZxCMa76_sBEOnNwr8B' });
            window.gtag('event', 'Formulario', {'Etiqueta' : 'Formulario'})
            window.gtag('event', 'generated_lead', { 'Etiqueta': 'Formulario' });
            window.fbq('trackCustom', 'Formulario Enviado')
            history.push("/gracias");
          } else {
            alert("Error al enviar el formulario, por favor vuelva a intentarlo o comuníquese por otra vía.")
          }
          setShowModal(false)
          const scrollY = document.body.style.top
          document.body.style.position = ''
          document.body.style.top = ''
          window.scrollTo(0, parseInt(scrollY || '0') * -1)
        }
      };
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.send(jsonData);

    } else {
      alert('Por favor complete todos los campos antes de enviar el formulario.')
    }
  }

  return (
    <form className="formulario" onSubmit={submitForm}>
      <input type='text' value={name} placeholder='Nombre' onChange={e => setName(e.target.value)} />
      <input type='email' value={email} placeholder='E-mail' onChange={e => setEmail(e.target.value)} />
      <input type='tel' value={tel} placeholder='Teléfono' onChange={e => setTel(e.target.value)} />
      <textarea value={message} placeholder='Consulta' onChange={e => setMessage(e.target.value)} />
      <input className='formulario-submit' type="submit" value="ENVIAR" />

      <div className="formulario-modal" ref={modalRef} style={{ display: showModal ? 'flex' : 'none' }}>
        <img src={process.env.PUBLIC_URL + '/imgs/cargando.gif'} alt="" />
        <p>Enviando el formulario, por favor aguarde</p>
      </div>
    </form>
  );
}

export default Formulario;