import './Gracias.css';
import { Link } from 'react-router-dom'

function Gracias() {

  return (
    <div className="gracias" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/imgs/background.png)" }}>
        <h2>¡Gracias por contactarte!</h2>
        <p>Un asesor se comunicará a la brevedad</p>
        <Link className="gracias-link" to="/">Volver al sitio</Link>
        <div className="gracias-social">
          <a className="gracias-social-button" href='https://es-la.facebook.com/saludinsumos/' target='_blank' rel="noreferrer"><img src={process.env.PUBLIC_URL + '/imgs/fb.svg'} alt="Facebook" /></a>
        </div>
    </div>
  );
}

export default Gracias;