import './Nosotros.css';

function Nosotros() {

  return (
    <div className="Nosotros" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/imgs/nosotros/background.png" }}>
      <div className="Nosotros-content">
        <p className="Nosotros-text">
          En <b>BARRIER INSUMOS MEDICOS</b>, somos líderes en la industria de Insumos y dispositivos médicos, brindando soluciones integrales para profesionales de la salud, hospitales, obras sociales, ministerios y municipios en toda Argentina. Nuestra misión es proporcionar productos médicos de calidad superior y un servicio personalizado que garantice el éxito de nuestros clientes.<br />
          <br />
          <b>MISIÓN</b><br />
          Mejorar la salud con insumos médicos confiables.<br />
          <br />
          <b>VISIÓN</b><br />
          Líderes en soluciones de Insumos Médicos innovadores.
        </p>
        <div className="Nosotros-image"></div>
      </div>
    </div >
  )
}

export default Nosotros;