import './Footer.css';

function Footer() {

  return (
    <div className="Footer">
        <img src={process.env.PUBLIC_URL + "/imgs/logo.svg"} alt="Barrier insumos médicos" />
    </div >
  )
}

export default Footer;