import { useEffect, useRef, useState } from 'react'
import './Marcas.css'

function Marcas() {

  const [firstShown, setFirstShown] = useState(0)

  const scrollerRef = useRef(null)

  useEffect(() => {
    setInterval(() => {
      setFirstShown(prevFirstShown => {
        if (window.innerWidth >= 550) {
          scrollerRef.current.scrollTo(prevFirstShown * 525, 0)
        } else {
          scrollerRef.current.scrollTo(prevFirstShown * (window.innerWidth-25), 0)
        }
        return prevFirstShown > 2 ? 0 : prevFirstShown + 1
      })
    }, 3000)
  }, [])

  return (
    <div className="Marcas">
      <div className="Marcas-content">
        <h3><b>MARCAS </b><br style={{ display: window.innerWidth <= 800 ? 'block' : 'none' }} />con las que trabajamos</h3>
        <div className="Marcas-cards" ref={scrollerRef}>
          <div className="Marcas-cards-inner">
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/1.png"} alt="ARROW" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/2.png"} alt="REDAX" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/3.png"} alt="PORTEX" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/4.png"} alt="Weck Hem-o-lok" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/5.png"} alt="B BRAUN" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/6.png"} alt="Convatec" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/7.png"} alt="Covidien" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/8.png"} alt="Medtronic" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/9.png"} alt="EuroMix" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/10.png"} alt="TAGUM" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/11.png"} alt="HocerMed" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/12.png"} alt="CooperSurgical" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/13.png"} alt="FENG" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/14.png"} alt="Cardinal Health" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/15.png"} alt="Shiley" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/16.png"} alt="Cook Medical" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/17.png"} alt="Boston Scientific" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/18.png"} alt="Pectus Carinatum" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/19.png"} alt="Balt" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/20.png"} alt="MERIT MEDICAL" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/21.png"} alt="Kimberly-Clark" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/22.png"} alt="Edwards Lifesciences" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/23.png"} alt="RUSCH" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/24.png"} alt="BARD" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/25.png"} alt="AURINCO" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/26.png"} alt="Abbott" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/27.png"} alt="TERUMO" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/28.png"} alt="PENTAX MEDICAL" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/29.png"} alt="Mar Flow Switzerland" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/30.png"} alt="SILMAG" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/31.png"} alt="BALTON" />
            <img src={process.env.PUBLIC_URL + "/imgs/marcas/32.png"} alt="KFF Dispositivos médicos quiúrgicos" />
          </div>
        </div>
      </div>
    </div >
  )
}

export default Marcas