import './App.css';
import Portada from './components/portada/Portada'
import Nosotros from './components/nosotros/Nosotros'
import Insumos from './components/insumos/Insumos'
import Marcas from './components/marcas/Marcas'
import Certificaciones from './components/certificaciones/Certificaciones'
import Clientes from './components/clientes/Clientes'
import Contacto from './components/contacto/Contacto'
import Footer from './components/footer/Footer'
import Gracias from './components/gracias/Gracias'
import { Switch, Route } from 'react-router-dom'

function App() {

  const onWAClick = () => {
    window.gtag('event', 'WhatsApp', {'Etiqueta' : 'WhatsApp'})
    window.fbq('trackCustom', 'WhatsApp')
  }

  const main = (
    <div>
      <Portada />
      <Nosotros />
      <Insumos />
      <div style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/imgs/background.png)" }}>
        <Marcas />
        <Certificaciones />
        <Clientes />
        <Contacto />
      </div>
      <Footer />
      <a className="App-wa" onClick={onWAClick} href="https://api.whatsapp.com/send?phone=5491167986758&text=%C2%A1Hola!%20Quiero%20m%C3%A1s%20informaci%C3%B3n%22" target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + "/imgs/wa.svg"} alt="WhatsApp" /></a>
    </div>
  )

  return (
    <div className="App">
      <Switch>
        <Route exact path="/">{main}</Route>
        <Route exact path="/gracias"><Gracias /></Route>
      </Switch>
    </div >
  )
}

export default App;