import { useEffect, useRef, useState } from 'react'
import './Clientes.css'

function Clientes() {

  const [firstShown, setFirstShown] = useState(0)

  const scrollerRef = useRef(null)

  useEffect(() => {
    setInterval(() => {
      setFirstShown(prevFirstShown => {
        if (window.innerWidth >= 550) {
          scrollerRef.current.scrollTo(prevFirstShown * 525, 0)
        } else {
          scrollerRef.current.scrollTo(prevFirstShown * (window.innerWidth-25), 0)
        }
        return prevFirstShown > 1 ? 0 : prevFirstShown + 1
      })
    }, 3000)
  }, [])

  return (
    <div className="Clientes">
      <div className="Clientes-content">
        <h3><b>Clientes </b><br style={{ display: window.innerWidth <= 800 ? 'block' : 'none' }} />que confían en nosotros</h3>
        <div className="Clientes-cards" ref={scrollerRef}>
          <div className="Clientes-cards-inner">
            <div className="Clientes-card" title="Hospital Posadas" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/imgs/clientes/1.png)" }}></div>
            <div className="Clientes-card" title="Ministerio de salud. Presidencia de la Nación" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/imgs/clientes/2.png)" }}></div>
            <div className="Clientes-card" title="Ministerio de salud. Gobierno de la provincia de Buenos Aires" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/imgs/clientes/3.png)" }}></div>
            <div className="Clientes-card" title="San Martín" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/imgs/clientes/4.png)" }}></div>
            <div className="Clientes-card" title="San Vicente municipio" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/imgs/clientes/5.png)" }}></div>
            <div className="Clientes-card" title="Vivamos Vicente López" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/imgs/clientes/6.png)" }}></div>
            <div className="Clientes-card" title="Hospital de niños Dr Pedro de Elizalde" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/imgs/clientes/7.png)" }}></div>
            <div className="Clientes-card" title="Buenos Aires Ciudad" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/imgs/clientes/8.png)" }}></div>
            <div className="Clientes-card" title="Hspital de niños Ricardo Gutiérrez" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/imgs/clientes/9.png)" }}></div>
            <div className="Clientes-card" title="PAMI INSSJP" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/imgs/clientes/10.png)" }}></div>
            <div className="Clientes-card" title="Hospital general de agudos Donacion F. Santojanni. Pilar 950, Ciudad de Buenos Aires" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/imgs/clientes/11.png)" }}></div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Clientes