import './Certificaciones.css'

function Certificaciones() {

  return (
    <div className="Certificaciones">
      <div className="Certificaciones-content">
        <hr />
        <h3>Certificaciones</h3>
        <div className="Certificaciones-cards">
          <div className="Certificaciones-card">
            <img src={process.env.PUBLIC_URL + "/imgs/certificaciones/1.png"} alt="" />
            <p>
              Buenos Aires Gobiernos de la Ciudad (Habilitación)
              <br /><br />
              Buenos Aires Gobiernos de la Ciudad (Habilitación Alimentos)
            </p>
          </div>
          <div className="Certificaciones-card">
            <img src={process.env.PUBLIC_URL + "/imgs/certificaciones/2.png"} alt="" />
            <p>
              ANMAT Nº 194/99
              <br /><br />
              ANMAT Nº 2319/02
            </p>
          </div>
          <div className="Certificaciones-card">
            <img src={process.env.PUBLIC_URL + "/imgs/certificaciones/3.png"} alt="" />
            <p>Ministerio de Salud (Presidencia de la Nación) Nº 028179</p>
          </div>
        </div>
        <hr />
      </div>
    </div >
  )
}

export default Certificaciones